<template>
  <list-template
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onChange="onChange"
    @onAdd="$router.push('./add')"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin],
  methods: {
    getData() {
      this.getDataApi('/zhaosheng/api/recruit-2024/exam/list-diag-subject-conf')
    },
    handleClick(row, handle) {
      const { type } = handle
      if (type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      }
    }
  },
  data() {
    return {
      tableConfig: [
        { prop: 'city_name', label: '参考城市' },
        { prop: 'subject_num', label: '考试科目数量' },
        { prop: 'creator', label: '创建人' },
        { prop: 'created_at', label: '创建时间' },
        { label: '操作', width: '100rem', handle: true }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
